.PromotionalPanel {
    /* top right bottom left */
    margin: 20px 20px 20px 20px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
}

@media (min-width: 500px){
    .PromotionalPanel {
        padding: 0;
    }
    
}