.Toolbar {
    height: 56px;
    width: 100%;
    position: fixed;
    top:0;
    left:0;
    background-color: #C62E2D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar nav {
    height: 100%;

}

.BarLogo {
    height: 80%;
}

@media (max-width: 499px){
    .DesktopOnly{
        display: none;
    }
}