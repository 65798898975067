.ImagePanel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    cursor: pointer;
}


@media (min-width: 500px){
    .ImagePanel {
        flex-flow: row;
    }
    
}