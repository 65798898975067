.Logo {
    background-color: white;
    cursor: default;
    padding: 5px 10px 20px 10px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 24px;
    color:#C62E2D;
    text-transform: capitalize;
    text-shadow: 1px 2px black;
}

.Logo img {
    height: 100%;
    /* padding: 10px 0px 0px 0px; */
}